<template>
    <div class="main-qualification">
        <!-- PAGE-HEADER -->
        <div class="page-header">
            <div>
                <h1 class="page-title"> {{$t('Statistics.AvailableBalance')}} </h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{$t('Statistics.AvailableBalance')}} </a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t('Statistics.Dashboard') }}</li>
                </ol>
            </div>
        </div>
        <!-- PAGE-HEADER END -->
        <!-- ROW-4 -->
        <div class="row">
            <div class="col-12 col-sm-12 ">
                <div class="card pt-5 ">
                    <div class="row mt-5 px-5">
                        <div class="col-sm-12 col-md-12 col-xl-12">
                            <div class="card box overflow-hidden">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <p class="text-muted fs-16 mb-0">{{ $t('Statistics.AvailableBalance') }}</p>
                                            <h3 class="mb-2 fw-semibold">{{getBalanceResult.getBalanceResponseDetailObject.balance}}</h3>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                   
                </div>
                <loading :name="loading" :active.sync="loading"
                         :can-cancel="true"
                         :is-full-page="true">
                </loading>
            </div><!-- COL END -->
        </div>

    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";

    export default {
        name: 'Dashboard',
        components: {
            Loading
        },
        data: function () {
            return {
                loading: false,
                getBalanceResult: {
                    getBalanceResponseDetailObject: {
                        balance: 0
                    }
                },
            }
        },
        methods: {

            GetInfo: function () {
                var root = this;
                var token = localStorage.getItem('token');
                root.loading = true;
                                
                root.$https.get('/Home/GetSmsBalanceQuery', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        if (response.data.result.succeeded) {
                            root.getBalanceResult = response.data.getBalanceResult;
                        }
                        else {
                            console.log(response.data.result.message);
                        }
                        root.loading = false;
                    }
                    else {
                        root.loading = false;
                        console.log('Something went wrong!');
                    }
                });
            },

        },
        created: function () {
            this.GetInfo();
        },
        mounted() {
            // Create an interval that will run the updateMessage method every 10 seconds
            this.interval = setInterval(this.GetInfo, 10000);
        },
        beforeDestroy() {
            // Clear the interval when the component is destroyed to prevent memory leaks
            clearInterval(this.interval);
        }
    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .btn-primary:hover {
        color: white !important;
    }

    .box {
        background-color: #edf4f9;
    }

    .bt {
        border-radius: 10px;
    }

    .bx {
        background: rgba(0, 144, 0, 0.05);
        border-radius: 6px;
        color: #009000;
    }

    .by {
        background: rgba(236, 118, 1, 0.1);
        border-radius: 6px;
        color: #EC7601;
    }
    .slide-item:hover {
        background-color: rgba(0, 144, 0, 0.9);
        color: white !important;
    }
</style>

